import React, { useEffect } from "react";
import './Sedes.css'

function Sedes() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="divSedes">
            <div className="titleSedes" data-aos="fade-up" data-aos-duration="1000">Nuestras sedes</div>
            <div className="rayaSedes"></div>

            <div className="contSedes">
                <div className="sede1">
                    <div className="titleSede" data-aos="fade-right" data-aos-duration="3000">Sede Buenaventura</div>
                    <div className="fotoCont">
                        <div className="fotoSede"></div>
                        <div className="dirSede">Cra 5ta A No. 2 – 32 Oficina 401 <br></br> Centro - Buenaventura. </div>
                    </div>
                </div>
                {/* <div className="sede2">
                    <div className="titleSede" data-aos="fade-left" data-aos-duration="3000">Sede Cartagena</div>
                    <div className="dirSede">Calle 32B 10B – 25 Oficina 411 <br></br> Edificio Lequerica - Cartagena. </div>
                </div> */}
                <div className="sede2">
                    <div className="titleSede" data-aos="fade-right" data-aos-duration="3000">Sede Cartagena</div>
                    <div className="fotoCont">
                        <div className="fotoSede2"></div>
                        <div className="dirSede">Calle 32B 10B – 25 Oficina 411 <br></br> Edificio Lequerica - Cartagena. </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export { Sedes };