import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Accordion.css';

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='divAcc' data-aos="fade-up" data-aos-duration="2200">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            Agenciamiento Aduanero
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Nuestra experiencia en más de 20 años, asesoría y dominio en el manejo de las diferentes modalidades aduaneras le permitirán contar con un aliado experto en el desarrollo aduanero de sus mercancías.
            El objetivo del agenciamiento aduanero es el de facilitar la introducción de mercancías de procedencia extranjera al territorio aduanero colombiano cumpliendo todas las disposiciones legales para que estas queden en libre circulación en el territorio nacional.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
            Asesoría en Clasificación Arancelaria
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Agencia de Aduanas Comexpal cuenta con personal altamente calificado en clasificación de todo tipo de mercancías.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Clasificación Arancelaria</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            La clasificación arancelaria es uno de los temas más importantes en una operación de comercio exterior, ya que todo lo relacionado con requisitos, régimen, descripciones mínimas, obligaciones, impuestos; están generalmente asociados a la subpartida arancelaria.
            La clasificación arancelaria es el mapa de ruta de la operación de comercio exterior
            La correcta clasificación arancelaria garantiza claridad acerca de todos los aspectos que se deben tener en cuenta cuando se decide importar o exportar un bien.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Asesoría en Legislación Aduanera</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Asesoría y gestión integral de procesos aduaneros, cambiarios y de comercio exterior, a través de personal calificado e idóneo, con amplia experiencia en el sector, lo que garantiza un desarrollo ágil en cada proceso.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Servicios de Tránsito Aduanero DTA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Gestión de mercancías en tránsito aduanero de una aduana a otra dentro del territorio nacional, con destino a zonas francas o depósitos aduaneros.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Elaboración Registros de Importación</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Tramitamos registros y licencias de importación ante el Ministerio de Comercio, Industria y Turismo, y la Ventanilla única de Comercio Exterior (VUCE).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Elaboración de DRFI</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Elaboración de documento por medio del cual se establecen los requisitos fitosanitarios y/o zoosanitarios que debe cumplir el país exportador de un producto o subproducto de origen vegetal o animal, para ingreso al territorio colombiano.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Presentación, Radicación y Seguimiento Registros Sanitarios ICA-INVIMA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Gestionamos los vistos buenos para la introducción y/o comercialización de los productos que lo requieran.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Asesoría Jurídica y Legal</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Asesoría jurídica permanentes en materia Aduanera y Fiscal.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Asesoría en Distribución y Despacho de Mercancías</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Asistimos a nuestros clientes en el estudio detallado de su operación logística con cobertura de uno o todos los eslabones de la cadena de abastecimiento.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>Asesoría en Transportes y Fletes Marítimos, Aéreos y Terrestres</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Nuestras alianzas son indispensables para poder brindarle a nuestros clientes opciones en los procedimientos y procesos de comercio exterior que garanticen su satisfacción y tranquilidad.

Transporte de Carga internacional, Distribución, ITR, Transporte terrestre de mercancías: hace parte de nuestro servicio logístico con el deseo de que nuestros clientes gocen de la tranquilidad de estar informados en tiempo real sobre el tránsito de sus mercancías.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}