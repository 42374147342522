import React, {useEffect} from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './Home.css';
import { useNavigate } from "react-router-dom";

function Home() {
    const options = {
        height: '77.5vh',
        // height: '20vh',
        type: 'fade',
        width: '100vw',
        perPage: 1,
        perMove: 1,
        rewind: true,
        arrows: false,
        autoplay: true,
        interval: 8500,
        speed: 1000,
        pauseOnHover: false,
        pagination: true,
        updateOnMove: true,
    }

    const navigator= useNavigate();

    // function toSedes() {
    //     navigator('./sedes');
    // }

    function toServicios() {
        navigator('./servicios');
    }

    function toContacto() {
        navigator('./contacto');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div id='sec1'>
                <button className='buttonInicio' onClick={toContacto}>Contáctenos</button>
                <Splide options={options} aria-label="My Favorite Images">
                    <SplideSlide>
                        <div className='fraseInicio' data-aos="fade-up" data-aos-duration="1000">Su asesor aduanero <br></br> Efectivo y Confiable</div>
                        <div className='img1' alt=''></div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='fraseInicio' data-aos="fade-up" data-aos-duration="1000">Agendamiento aduanero en <br></br> Importaciones y Exportaciones</div>
                        <div className='img2' alt=''></div>
                    </SplideSlide>
                    <SplideSlide>
                        <div className='fraseInicio' data-aos="fade-up" data-aos-duration="1000">Contamos con más de 20 años <br></br> de experiencia</div>
                        <div className='img3' alt=''></div>
                    </SplideSlide>
                </Splide>
            </div>
            <div id='sec2' data-aos="fade-right" data-aos-duration="2000">
                <div className='diagonal'>
                    <div className='contServicios'>
                        <div className='titleServicios' data-aos="fade-up" data-aos-duration="2500">Servicios</div>
                        <div className='titleServicio'>Agenciamiento Aduanero.</div>
                        <div className='descServicio'>Nuestra trayectoria le permitirá contar con un aliado experto en el desarrollo aduanero de sus importaciones.</div>
                    </div>
                </div>
            </div>

            <div id='sec3' data-aos="fade-right" data-aos-duration="2000">
                <div className='diagonal2'>
                    <div className='contServicios2'>
                        <div className='titleServicios' data-aos="fade-up" data-aos-duration="2500">Servicios</div>
                        <div className='titleServicio'>Asesoría jurídica y legal.</div>
                        <div className='descServicio'>Asesoría jurídica permanentes en materia Aduanera y Fiscal.</div>
                    </div>
                </div>
            </div>

            <div id='sec4' data-aos="fade-right" data-aos-duration="2000">
                <div className='diagonal'>
                    <div className='contServicios'>
                        <div className='titleServicios' data-aos="fade-up" data-aos-duration="2500">Servicios</div>
                        <div className='titleServicio'>Tenemos muchos más servicios...</div>
                        <div className='descServicio'>¿Quieres conocer más?</div>
                        <button className='buttonInicio' style={{ marginTop: '7vh', marginLeft: '0vw' }} onClick={toServicios}>Ver más servicios</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Home };