import React, {useEffect} from "react";
import './Contacto.css';

function Contacto() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="background">
            <div className="container">
                <div className="screen" data-aos="fade-up" data-aos-duration="1500">
                    <div className="screen-header">
                        <div className="screen-header-left">
                            <div className="screen-header-button close"></div>
                            <div className="screen-header-button maximize"></div>
                            <div className="screen-header-button minimize"></div>
                        </div>
                        <div className="screen-header-right">
                            <div className="screen-header-ellipsis"></div>
                            <div className="screen-header-ellipsis"></div>
                            <div className="screen-header-ellipsis"></div>
                        </div>
                    </div>
                    <div className="screen-body">
                        <div className="screen-body-item left">
                            <div className="app-title">
                                <span>CONTACTANOS</span>
                                {/* <span>US</span> */}
                            </div>
                            <div className="app-contact">INFORMACION DE CONTACTO : +57 (602) 2442507</div>
                        </div>
                        <div className="screen-body-item">
                            <div className="app-form">
                                <div className="app-form-group">
                                    <input className="app-form-control" placeholder="NOMBRE"/>
                                </div>
                                <div className="app-form-group">
                                    <input className="app-form-control" placeholder="CORREO"/>
                                </div>
                                <div className="app-form-group">
                                    <input className="app-form-control" placeholder="NRO. CONTACTO"/>
                                </div>
                                <div className="app-form-group message">
                                    <input className="app-form-control" placeholder="MENSAJE"/>
                                </div>
                                <div className="app-form-group buttons">
                                    <button className="app-form-button">CANCELAR</button>
                                    <button className="app-form-button">ENVIAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Contacto };