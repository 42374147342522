import React, {useEffect} from "react";
import './Servicios.css';
import Accordion from '../Accordion/Accordion';

function Servicios() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <div className="divServicios">

            <div className="titleServices" data-aos="fade-up" data-aos-duration="1500">Nuestros servicios</div>
            <div className="rayaServicios"></div>

            <Accordion />
        </div>
    );
}

export { Servicios };