import React, { useEffect } from "react";
import './Nosotros.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

function Nosotros() {

    const options = {
        height: '70vh',
        width: '89vw',
        type: 'loop',
        perPage: 3,
        perMove: 1,
        rewind: true,
        arrows: true,
        autoplay: true,
        interval: 3500,
        speed: 1000,
        pauseOnHover: false,
        pagination: false,
        breakpoints: {
            1024: {
                perPage: 3,

            },
            767: {
                perPage: 2,

            },
            415: {
                perPage: 1,

            },
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="DivNosotros">
            <div className="contTitulo">
                <div className="raya"></div>
                <div className="titleSobreNosotros" data-aos="fade-up" data-aos-duration="1000">Sobre Nosotros</div>
            </div>

            <div className="ContNosotrosInfo">
                <div className="imgNosotros"></div>
                <div className="infoNosotros">
                    <div className="titleNosotros" data-aos="fade-up" data-aos-duration="1000" >¿Quiénes somos?</div>
                    <div className="descNosotros" data-aos="fade-left" data-aos-duration="1000">Somos una Empresa de Servicios en Comercio Internacional que, bajo criterios de competitividad y legalidad, damos soluciones acertadas para el manejo de operaciones de Comercio Exterior.
                        Somos una Agencia de Aduanas reconocida en el mercado por su legalidad, servicio eficiente, asesoría personalizada, innovación tecnológica y operativa, que hace de nosotros su aliado ideal para la internacionalización de su negocio.</div>
                </div>
            </div>

            <div className="contTitulo" style={{ marginTop: '5vh' }} data-aos="fade-up" data-aos-duration="1000">
                <div className="raya"></div>
                <div className="titleSobreNosotros">Información legal</div>
            </div>

            <div className="descInfoLegal" data-aos="fade-left" data-aos-duration="1000">Aprobados como Agencia de Aduanas con Resolución DIAN Resolución Número 008477. ☑️</div>

            <div className="contTitulo" style={{ marginTop: '2vh' }} data-aos="fade-up" data-aos-duration="1000">
                <div className="raya"></div>
                <div className="titleSobreNosotros">Nuestra política</div>
            </div>

            <div className="ContNosotrosInfo">
                <div className="imgPolitica"></div>
                <div className="infoNosotros">
                    <div className="titleNosotros" data-aos="fade-up" data-aos-duration="1000">Nuestra política...</div>
                    <div className="descNosotros" data-aos="fade-left" data-aos-duration="1000">Está basada en lograr un mejoramiento continuo en el desempeño de nuestro sistema de gestión para satisfacer en forma permanente las necesidades de nuestros clientes mediante la prestación de servicios de Agenciamiento Aduanero en los términos de Oportunidad, Confiabilidad y Seguridad Acordados. Buscando la optimización de recursos en nuestros procesos operativos. Contamos para ello, con un equipo humano idóneo, capacitado y motivado que actúa dentro de los parámetros éticos y morales establecidos.</div>
                </div>
            </div>

            <div className="secMision">
                <div className="imgMision"></div>
                <div className="contMision">
                    <div className="titleMision" data-aos="fade-up" data-aos-duration="1000">Misión</div>
                    <div className="borderBottom"></div>
                    <div className="descMision" data-aos="fade-left" data-aos-duration="1000">Ser el mejor aliado de nuestros clientes a través de la prestación de servicios logísticos de comercio exterior; de manera ágil, efectiva, segura y confiable; generando valor a nuestros clientes, bajo la implementación de herramientas innovadoras y eficientes y dentro del marco de cumplimiento de la normatividad vigente.</div>
                </div>
            </div>

            <div className="secVision">
                <div className="contMision">
                    <div className="titleMision" data-aos="fade-up" data-aos-duration="1000">Visión</div>
                    <div className="borderBottom"></div>
                    <div className="descMision" data-aos="fade-right" data-aos-duration="1000">Establecernos y ser reconocidos como la mejor opción para nuestros clientes brindando servicios de Comercio Exterior y Logística; siendo un aliado líder, altamente competitivo, innovador y confiable.</div>
                </div>
                <div className="imgVision"></div>
            </div>

            <div className="contTitulo" style={{ marginTop: '10vh' }}>
                <div className="raya"></div>
                <div className="titleSobreNosotros" data-aos="fade-up" data-aos-duration="1000">Objetivos de Calidad</div>
            </div>

            <div className="contObjectives">
                <div className="incrementar">
                    <div className="imgIncrementar" data-aos="fade-right" data-aos-duration="1000"></div>
                    <div className="textIncrementar">Incrementar la satisfacción del cliente</div>
                </div>
                <div className="oportunidad">
                    <div className="imgOportunidad" data-aos="fade-left" data-aos-duration="1000"></div>
                    <div className="textOportunidad">Mejorar la oportunidad en el Agenciamiento Aduanero</div>
                </div>
            </div>
            <div className="contObjectives" style={{ marginTop: '0' }}>
                <div className="competencias">
                    <div className="imgCompetencias" data-aos="fade-right" data-aos-duration="1000"></div>
                    <div className="textCompetencias">Mejorar las competencias de personal operativo</div>
                </div>
                <div className="clima">
                    <div className="imgClima" data-aos="fade-left" data-aos-duration="1000"></div>
                    <div className="textClima">Mejorar el clima organizacional</div>
                </div>
            </div>

            <div className="contTitleValores" style={{ marginBottom: '10vh' }}>
                <div className="titleValores" data-aos="fade-up" data-aos-duration="1000">Valores y principios</div>
                <div className="rayaValores"></div>
            </div>

            <div className="SplideBox">
                <Splide options={options} aria-label="My Favorite Images">
                    <SplideSlide className="boxSpl">
                        <div className="titleVoluntad">Voluntad Empresarial</div>
                        <div className='imgValor' alt=''></div>
                        <div className='descValor'>Orientarse hacia lo que es importante. Capacidad para establecer prioridades. Trabajo en equipo y calidad en los resultados.</div>
                    </SplideSlide>
                    <SplideSlide className="boxSpl">
                        <div className="titleVoluntad">Respeto</div>
                        <div className='imgValor' alt=''></div>
                        <div className='descValor'>Respeto por las personas, mentalidad abierta y sincera, dando a otros la oportunidad de expresar su opinión.</div>
                    </SplideSlide>
                    <SplideSlide className="boxSpl">
                        <div className="titleVoluntad">Ética profesional</div>
                        <div className='imgValor' alt=''></div>
                        <div className='descValor'>Juicio en las apreciaciones, moral y compromiso.</div>
                    </SplideSlide>
                    <SplideSlide className="boxSpl">
                        <div className="titleVoluntad">Creatividad e innovación</div>
                        <div className='imgValor' alt=''></div>
                        <div className='descValor'>Habilidad para innovar, apoyar y reforzar a otros.</div>
                    </SplideSlide>
                    <SplideSlide className="boxSpl">
                        <div className="titleVoluntad">Rapidez de acción y anticipación</div>
                        <div className='imgValor' alt=''></div>
                        <div className='descValor'>Adaptarse al cambio y encontrar siempre una solución.</div>
                    </SplideSlide>
                </Splide>
            </div>

        </div>
    );
}

export { Nosotros };